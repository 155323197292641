import { grey, slate, spacing, BreakpointWidths } from '@pelotoncycle/design-system';
import React, { useEffect, useRef, useState } from 'react';
import ReactSlick from 'react-slick';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { hoverTransition } from '@peloton/styles';
import 'slick-carousel/slick/slick.css';
import type { TypeComponentGenericTextWithMedia } from '@page-builder/lib/types';
import { applyMediaTreatment } from './utils';

type Props = {
  mediaArray: TypeComponentGenericTextWithMedia[];
  parentName?: string;
  product: string;
};

export const MobileCarousel: React.FC<React.PropsWithChildren<Props>> = ({
  mediaArray,
  parentName,
  product,
}) => {
  const children = applyMediaTreatment(mediaArray);
  const { trackEvent } = useTracking();
  const sliderRef = useRef<ReactSlick>(null);
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
  const trackPagination = () => {
    trackEvent({
      event: 'Clicked Carousel Slide',
      properties: {
        unitName: `${product} MobileCarousel Slide ${currentSlideIndex + 1}`,
        parentType: 'Component: Overview',
        parent: `${product} Overview Module`,
      },
    });
  };
  const toTileFocusHandler = (index: number) => () => {
    const newPage = Math.floor(index);
    sliderRef.current?.slickGoTo(newPage, true);
  };

  useEffect(() => {
    const slides = sliderRef.current?.innerSlider?.list?.querySelectorAll(
      'li.slick-slide[aria-hidden=true]',
    );
    slides?.forEach(slide => {
      slide.setAttribute('aria-hidden', 'false');
    });
  });

  return (
    <OuterSliderWrapper data-test-id={`mobile-overview-carousel-${parentName}`}>
      <InnerSliderWrapper>
        <StyledReactSlick
          ref={sliderRef}
          variableWidth={false}
          arrows={false}
          dots={true}
          customPaging={() => (
            <Dot aria-label={`pagination-dot`} data-test-id="pagination-dot" />
          )}
          appendDots={dots => <DotWrapper>{dots}</DotWrapper>}
          afterChange={event => {
            setCurrentSlideIndex(event);
            trackPagination();
          }}
          centerPadding="0"
          centerMode={true}
          lazyLoad="ondemand"
        >
          {React.Children.map(children, (child, index) => (
            <TileWrapper onFocus={toTileFocusHandler(index)}>{child}</TileWrapper>
          ))}
        </StyledReactSlick>
      </InnerSliderWrapper>
    </OuterSliderWrapper>
  );
};

const DotWrapper = styled.ul`
  margin-top: ${spacing[32]};
`;

const OuterSliderWrapper = styled.div`
  position: relative;
  @media (min-width: ${BreakpointWidths.desktop}px) {
    display: none;
  }
`;

const InnerSliderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  margin-bottom: ${spacing[24]};

  .slick-list {
    overflow: visible;
  }

  .slick-bottom {
    margin-top: ${spacing[24]};
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    line-height: 0;
  }

  /* Target placeholder slides only, real slides are list items */
  div.slick-slide {
    background: ${grey[30]};
    aspect-ratio: 4 / 5;
    border-radius: ${spacing[4]};
  }
`;

const StyledReactSlick = styled(ReactSlick)<{ children: React.ReactNode }>`
  width: 100%;
`;

const TileWrapper = styled.div`
  &:focus {
    outline: none;
  }
`;

const Dot = styled.button`
  background-color: ${grey[50]};
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 8px;
  margin: 0 4px;
  width: 8px;

  .slick-active & {
    background-color: ${slate[50]};
  }

  ${hoverTransition({
    property: 'background-color',
    to: slate[50],
  })}
`;
