import type { LinkButtonProps } from '@pelotoncycle/design-system';
import {
  BreakpointWidths,
  Display,
  Eyebrow,
  Flex,
  Grid,
  Label,
  spacing,
  Stars,
} from '@pelotoncycle/design-system';
import { OuterContainer } from '@pelotoncycle/page-builder';
import React from 'react';
import { useTracking } from 'react-tracking';
import styled from 'styled-components';
import { Link } from '@peloton/next/components/Link';
import { LinkButton } from '@peloton/next/components/LinkButton';
import { TrackingEvent } from '@ecomm/analytics/models';
import { CONTAINER_MAXWIDTH } from '../tokens';
import type { ReviewCardProps } from './ReviewCard';
import ReviewCard from './ReviewCard';
import { themes } from './themes';

export type Props = {
  reviewItems?: ReviewCardProps[];
  primaryButton?: LinkButtonProps;
  secondaryButton?: LinkButtonProps;
  title?: string;
  totalReviews?: string;
  rating?: number;
  theme?: 'White' | 'Black';
  deviceType?: string;
};

const GenericReviewCards: React.FC<React.PropsWithChildren<Props>> = ({
  reviewItems,
  primaryButton,
  secondaryButton,
  title,
  totalReviews,
  rating,
  theme = 'White',
  deviceType,
}) => {
  const {
    backgroundColor,
    textColor,
    secondaryTextColor,
    borderColor,
    itemTextColor,
    starsColor,
  } = themes[theme];

  const { trackEvent } = useTracking();

  return (
    <OuterContainer
      id="generic-review-cards"
      theme={theme}
      maxWidth={CONTAINER_MAXWIDTH}
      horizontalPadding={{
        mobile: spacing[16],
        tablet: spacing[64],
        desktop: spacing[40],
      }}
      verticalPadding={{
        mobile: spacing[32],
        tablet: spacing[64],
        desktop: spacing[80],
      }}
      flexDirection="column"
      backgroundColor={backgroundColor}
      style={{ boxSizing: 'content-box' }}
    >
      <Header
        alignItems={{ tablet: 'center' }}
        justifyContent="space-between"
        flexDirection={{ mobile: 'column', tablet: 'row' }}
        gap={{ mobile: spacing[32] }}
        borderColor={borderColor}
      >
        <Flex flexDirection="column" gap={spacing[16]}>
          {!!rating && (
            <Flex gap={spacing[8]} alignItems="center">
              <Stars rating={rating} starSize={14} theme={starsColor} />
              <RatingsLink
                data-test-id="rating-stars-link"
                href={!!deviceType ? `/${deviceType}/reviews` : '/reviews'}
                textColor={textColor}
                hoverColor={secondaryTextColor}
                onClick={() => {
                  const ratingsLinkHref = !deviceType
                    ? '/reviews'
                    : `/${deviceType}/reviews`;

                  trackEvent({
                    event: TrackingEvent.ClickedLink,
                    properties: {
                      parent: title,
                      parentType: 'Component: Reviews',
                      href: ratingsLinkHref,
                      linkName: `${rating}/5`,
                      unitName: 'ratingStarsLink',
                      linkTo: ratingsLinkHref,
                    },
                  });
                }}
              >
                <Eyebrow size="medium">{rating}/5</Eyebrow>
              </RatingsLink>
            </Flex>
          )}
          {!!title && (
            <Display size="small" textColor={textColor}>
              {title}
            </Display>
          )}
          {!!totalReviews && (
            <Label size="extraLarge" textColor={secondaryTextColor}>
              {totalReviews}
            </Label>
          )}
        </Flex>
        <ButtonGroup
          primaryButton={primaryButton}
          secondaryButton={secondaryButton}
          theme={theme}
          visible="desktop"
          title={title}
        />
      </Header>
      <Items
        gap={{ mobile: spacing[40], desktop: spacing[24] }}
        verticalPadding={{ desktop: spacing[40] }}
      >
        {reviewItems?.map((reviewItem, index) => (
          <ReviewCard
            key={index}
            {...reviewItem}
            textColor={textColor}
            secondaryTextColor={itemTextColor}
            borderColor={borderColor}
            starsColor={starsColor}
          />
        ))}
      </Items>
      <ButtonGroup
        primaryButton={primaryButton}
        secondaryButton={secondaryButton}
        theme={theme}
        visible="mobile"
      />
    </OuterContainer>
  );
};

export default GenericReviewCards;

type ButtonGroupProps = Pick<Props, 'primaryButton' | 'secondaryButton' | 'theme'> &
  Pick<React.HTMLAttributes<HTMLElement>, 'aria-hidden'> & {
    visible?: 'desktop' | 'mobile';
    title?: string;
  };

const ButtonGroup: React.FC<React.PropsWithChildren<ButtonGroupProps>> = ({
  primaryButton,
  secondaryButton,
  theme = 'White',
  visible,
  title,
  ...props
}) => {
  const { secondaryButtonColor } = themes[theme];
  const isVisible = (breakpoint: ButtonGroupProps['visible']) =>
    visible === breakpoint ? 'flex' : 'none';

  const { trackEvent } = useTracking();
  return (
    <Flex
      display={{ mobile: isVisible('mobile'), desktop: isVisible('desktop') }}
      flexDirection={{ mobile: 'column', tablet: 'row' }}
      justifyContent={{ mobile: 'center', tablet: 'flex-start', desktop: 'flex-end' }}
      gap={spacing[16]}
      {...props}
    >
      {primaryButton && (
        <LinkButton
          onClick={() => {
            trackEvent({
              event: TrackingEvent.ClickedLink,
              properties: {
                parent: title,
                parentType: 'Component: Reviews',
                href: primaryButton?.href,
                linkName: primaryButton?.text,
                unitName: 'viewAllReviewsCta',
                linkTo: primaryButton?.href,
              },
            });
          }}
          href={primaryButton.href}
          text={primaryButton.text}
          width={{ mobile: 'adaptive', tablet: 'fixed' }}
          size="small"
          color={primaryButton.color ?? 'primary'}
          variant={primaryButton.variant ?? 'solid'}
        />
      )}
      {secondaryButton && (
        <LinkButton
          href={secondaryButton.href}
          text={secondaryButton.text}
          width={{ mobile: 'adaptive', tablet: 'fixed' }}
          size="small"
          color={secondaryButton.color ?? secondaryButtonColor}
          variant={secondaryButton.variant ?? 'outline'}
        />
      )}
    </Flex>
  );
};

const Header = styled(Flex)<{ borderColor: string }>`
  width: 100%;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
  padding-bottom: ${spacing[40]};
  margin-bottom: ${spacing[40]};
  @media (min-width: ${BreakpointWidths.desktop}px) {
    margin-bottom: 0;
  }
`;

const Items = styled(Grid)`
  grid-template-columns: 1fr;
  width: 100%;
  margin-bottom: ${spacing[40]};
  @media (min-width: ${BreakpointWidths.desktop}px) {
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: 0;
  }
`;

const RatingsLink = styled(Link)<{ textColor?: string; hoverColor?: string }>`
  *,
  &::after {
    ${({ textColor }) => textColor && `color: ${textColor}`};
  }
  ${({ hoverColor }) =>
    hoverColor &&
    `
    &:hover {
      *, &::after {
        color: ${hoverColor};
      }
    }
    `}
`;
